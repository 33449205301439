export const strict = false

export const state = () => ({
  user: null,
  error: '',
  isDesktop: false,
})

export const mutations = {
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    if (typeof payload === 'string') {
      payload = {
        status: 'info',
        msg: payload
      }
    }
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)
      commit('setDevice', app.$device)
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
