import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d9b0b34 = () => interopDefault(import('../pages/32rf21/index.vue' /* webpackChunkName: "pages/32rf21/index" */))
const _032cebf4 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _782a6450 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _8e9de1f2 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _75228b73 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _137c7602 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _48f422fa = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _ce3f65e0 = () => interopDefault(import('../pages/32rf21/comments.vue' /* webpackChunkName: "pages/32rf21/comments" */))
const _4d63b92d = () => interopDefault(import('../pages/32rf21/desc.vue' /* webpackChunkName: "pages/32rf21/desc" */))
const _018b2a21 = () => interopDefault(import('../pages/32rf21/feedback.vue' /* webpackChunkName: "pages/32rf21/feedback" */))
const _1f74818a = () => interopDefault(import('../pages/32rf21/kino.vue' /* webpackChunkName: "pages/32rf21/kino" */))
const _dddc5f22 = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _595b6d11 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _953a877a = () => interopDefault(import('../pages/film/_id/index.vue' /* webpackChunkName: "pages/film/_id/index" */))
const _2d6f13c7 = () => interopDefault(import('../pages/name/_id.vue' /* webpackChunkName: "pages/name/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/32rf21",
    component: _4d9b0b34,
    name: "32rf21"
  }, {
    path: "/copyright",
    component: _032cebf4,
    name: "copyright"
  }, {
    path: "/popular",
    component: _782a6450,
    name: "popular"
  }, {
    path: "/privacy",
    component: _8e9de1f2,
    name: "privacy"
  }, {
    path: "/signin",
    component: _75228b73,
    name: "signin"
  }, {
    path: "/success",
    component: _137c7602,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _48f422fa,
    name: "termsofuse"
  }, {
    path: "/32rf21/comments",
    component: _ce3f65e0,
    name: "32rf21-comments"
  }, {
    path: "/32rf21/desc",
    component: _4d63b92d,
    name: "32rf21-desc"
  }, {
    path: "/32rf21/feedback",
    component: _018b2a21,
    name: "32rf21-feedback"
  }, {
    path: "/32rf21/kino",
    component: _1f74818a,
    name: "32rf21-kino"
  }, {
    path: "/profile/favs",
    component: _dddc5f22,
    name: "profile-favs"
  }, {
    path: "/",
    component: _595b6d11,
    name: "index"
  }, {
    path: "/film/:id",
    component: _953a877a,
    name: "film-id"
  }, {
    path: "/name/:id?",
    component: _2d6f13c7,
    name: "name-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
