const dev = process.env.NODE_ENV !== 'production'

export default function({ store, redirect }) {
  const user = store.state.user
  if (!user) {
    if (dev) console.log('middleware auth-admin | user not auth')
    redirect('/')
  } else {
    if (dev) console.log('middleware auth-admin | user', user)
    if (user.group !== 0) redirect('/')
  }
}
