<template>
  <main class="index">
    <!-- <LastComments v-if="lastComments && lastComments.length" :comments="lastComments" /> -->

    <div class="comics">
      <div class="total">
        Найдено: {{ total }}
      </div>
      <Items :items="items" />
      <div class="more" />

      <div class="description">
        <h1>
          Смотреть аниме онлайн{{
            genre ? ' жанра ' + genre : ''
          }}{{
            year ? ' (' + year + ')' : ''
          }} бесплатно
        </h1>

        <template v-if="genre && year && country">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть аниме онлайн бесплатно в жанре {{ genre }}, выпущенные в {{ year }} году и производства {{ country }}.</p>

          <p>Ищете захватывающие приключения, напряженные схватки и неожиданные повороты сюжета? Тогда вы попали в нужное место! На нашем сайте представлен широкий выбор самых популярных, которые не оставят вас равнодушными.</p>

          <p>Забудьте о неудобствах и ограничениях, связанных с традиционными способами просмотра. Мы предоставляем удобный и легкий доступ к вашим любимым аниме прямо из уютного домашнего кресла. Никакой регистрации, никаких платных подписок - только качественный контент без ограничений.</p>

          <p>Наши аниме в жанре {{ genre }} отличаются захватывающим сюжетом, кинематографичной постановкой и выдающимися актерскими исполнениями. Будьте готовы к адреналиновым взрывам и эмоциональным горкам, которые заставят вас верить в героев и жить с ними каждый момент их путешествия.</p>

          <p>Смотрите любимые аниме в любое время, удобное именно для вас. Наш сайт доступен на всех устройствах, от компьютеров до мобильных телефонов, чтобы вы могли наслаждаться просмотром где угодно и когда угодно.</p>

          <p>Не упустите возможность окунуться в захватывающий мир жанра {{ genre }} {{ year }} года, созданных лучшими творческими командами {{ country }}. Присоединяйтесь к нам и погрузитесь в увлекательные истории, которые запомнятся на долгое время!</p>

          <p>Итак, что же вы ждете? Выбирайте аниме, запасайтесь попкорном и наслаждайтесь просмотром! Смотреть аниме онлайн бесплатно в жанре {{ genre }} - это легко и увлекательно, только на нашем сайте.</p>
        </template>
        <template v-if="year">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть лучшие аниме онлайн абсолютно бесплатно. Мы предлагаем огромный выбор аниме, выпущенных в {{ year }} году, чтобы удовлетворить любой вкус и предпочтение. Насладитесь захватывающими сюжетами, увлекательными персонажами и великолепной игрой актеров, не покидая уют домашнего дивана!</p>

          <p>Наш сайт обеспечивает простой и удобный интерфейс, позволяющий легко находить и начинать просмотр желаемых аниме всего в несколько кликов. Мы постоянно обновляем нашу библиотеку, чтобы предоставлять вам самые свежие и актуальные аниме, выпущенные в {{ year }} году.</p>

          <p>Независимо от вашего предпочтения — драмы, комедии, фантастики, криминальные истории или романтические приключения, у нас вы обязательно найдете что-то, что зацепит ваше внимание и оставит яркие впечатления.</p>

          <p>Не тратьте время на поиск качественных аниме в сети — забудьте о рекламе и ограничениях. Присоединяйтесь к нам прямо сейчас и окунитесь в захватывающий мир кинематографа {{ year }} года! Смотрите аниме онлайн бесплатно и наслаждайтесь незабываемым просмотром!</p>
        </template>
        <template v-if="country">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть бесплатно онлайн лучшие аниме из {{ country }}. Откройте для себя захватывающие истории, захватывающие сюжеты и великолепную актерскую игру прямо у вас дома. Мы предоставляем широкий выбор аниме различных жанров, чтобы удовлетворить вкусы каждого зрителя.</p>

          <p>На нашем сайте вы можете наслаждаться просмотром аниме в удобное для вас время, без необходимости скачивать или регистрироваться. Наша коллекция постоянно обновляется, чтобы вы всегда могли найти что-то интересное и новое. Погрузитесь в мир качественного кинематографа {{ country }} и проведите время с удовольствием, следуя за увлекательными сюжетами и любимыми персонажами.</p>

          <p>Смотрите свои любимые аниме на русском языке с субтитрами, чтобы углубиться в атмосферу произведений и наслаждаться всеми нюансами производства {{ country }}. Не упустите возможность путешествовать в увлекательные миры истории, романтики, драмы, комедии и многого другого.</p>

          <p>Присоединяйтесь к нам и наслаждайтесь просмотром аниме онлайн бесплатно прямо сейчас! Погрузитесь в мир кино {{ country }} вместе с нами.</p>
        </template>
        <template v-else>
          <p>Добро пожаловать на наш уникальный портал, где вы сможете полностью погрузиться в мир аниме! Мы предоставляем возможность смотреть аниме онлайн бесплатно - это ваш путеводитель в увлекательное путешествие по захватывающим мирам, наполненным яркими персонажами, эмоциями и невероятными приключениями.</p>

          <p>На нашем сайте вы найдете обширную библиотеку аниме: от классических шедевров до новинок, от популярных сериалов до независимых произведений, от романтики до научной фантастики. Мы стараемся удовлетворить вкусы каждого анимешника, предлагая разнообразие жанров и стилей.</p>

          <p>Смотреть аниме онлайн бесплатно на нашем сайте легко и удобно. Никаких ограничений по времени или региону – вы можете наслаждаться просмотром в любое удобное для вас время. Нет необходимости скачивать файлы, просто выберите понравившееся аниме, нажмите "Воспроизвести" и наслаждайтесь.</p>

          <p>Мы следим за актуальностью контента и регулярно обновляем нашу коллекцию, чтобы вы всегда были в курсе последних аниме-новинок. Все это доступно вам совершенно бесплатно, потому что мы хотим подарить вам удовольствие от просмотра без лишних затрат.</p>

          <p>Присоединяйтесь к нам и окунитесь в мир японской анимации, где каждый момент наполнен эмоциями, каждый персонаж – уникален, а каждая история – захватывающа. Смотреть аниме онлайн бесплатно с нами – это ваше билет в волшебную вселенную аниме. Наслаждайтесь этим удивительным путешествием!</p>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {
    Items: () => import('~/components/Items.vue'),
    // LastComments: () => import('~/components/LastComments.vue'),
  },
  head() {
    const title = `Смотреть онлайн аниме ${
      this.genre ? ' жанра ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    } бесплатно на русском в хорошем качестве`
    const fullPath = `https://1xanime.ru${
      this.genre || this.country || this.year
        ? `?page=${this.page}${
          this.genre ? `&genre=${this.genre}` : ''
        }${
          this.country ? `&country=${this.country}` : ''
        }${
          this.year ? `&year=${this.year}` : ''
        }`
        : ''
    }`
    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `смотреть аниме бесплатно на русском`
        },
        {
          hid: 'description',
          name: 'description',
          content: `На нашем сайте вы можете бесплатно смотреть онлайн аниме на русском и с хорошим качеством изображения${
      this.genre ? '. ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    }`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `На нашем сайте вы можете бесплатно смотреть онлайн аниме на русском и с хорошим качеством изображения${
      this.genre ? '. ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    }`
        },
      ]
    }
  },
  watchQuery: ['genre', 'year', 'country', 'sort', 'trailer'],
  async asyncData({ $axios, query }) {
    const genre = query.genre ? query.genre : ''
    const year = query.year ? query.year : ''
    const country = query.country ? query.country : ''
    const sort = query.sort ? query.sort : ''
    const trailer = query.trailer
    let url = `/api/index?page=0`
    if (genre) url += `&genre=${encodeURIComponent(genre)}`
    if (year) url += `&year=${encodeURIComponent(year)}`
    if (country) url += `&country=${encodeURIComponent(country)}`
    if (sort) url += `&sort=${encodeURIComponent(sort)}`
    if (trailer) url += `&trailer=${trailer}`

    const { data } = await $axios.get(url)
    
    return {
      ...data,
      page: 0,
      loading: false,
      genre,
      year,
      country,
      sort,
      trailer,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showMore)

    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.addEventListener('scroll', this.lazyLoadImgs)

  //   if (this.$device.isDesktop) {
  //     setTimeout(() => {
  //       const rsyaVertical = document.createElement('script')
  //       rsyaVertical.innerHTML = `
  // window.yaContextCb.push(()=>{
  // Ya.Context.AdvManager.render({
  //   renderTo: 'yandex_rtb_R-A-2270065-5',
  //   blockId: 'R-A-2270065-5'
  // })
  // })`
  //       document.body.appendChild(rsyaVertical)
  //     }, 200)
  //   }
  },
  beforeDestroy() {
    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.removeEventListener('scroll', this.lazyLoadImgs)

    window.removeEventListener('scroll', this.showMore)
  },
  methods: {
    async showMore() {
      if (this.loading) return
      const elem = document.querySelector('.more')
      if (!elem) return
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        this.loading = true
        const genre = this.genre ? this.genre : ''
        const year = this.year ? this.year : ''
        const country = this.country ? this.country : ''
        let url = `/api/catalog?page=${++this.page}`
        if (genre) url += `&genre=${encodeURIComponent(genre)}`
        if (year) url += `&year=${encodeURIComponent(year)}`
        if (country) url += `&country=${encodeURIComponent(country)}`
        const { data } = await this.$axios.get(url)
        const items = this.items.concat(data.items)
        this.items = items
        this.loading = false
      }
    },
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
