var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search"},[_c('div',{staticStyle:{"position":"relative"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"ssearch",class:{ active: _vm.searchQuery },attrs:{"id":"search","type":"search","name":"search","autocomplete":"off","placeholder":"Начните вводить название"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},_vm.trottled],"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),_vm._v(" "),(_vm.searchQuery.length)?_c('div',{staticClass:"clr",on:{"click":function($event){_vm.searchQuery = ''}}}):_vm._e()]),_vm._v(" "),(_vm.searchQuery.length > 2)?_c('div',{staticClass:"datalist"},[(!_vm.searchHints.length)?_c('div',{staticClass:"no-match"},[_c('span',[_vm._v("Ничего не найдено")])]):_c('ul',_vm._l((_vm.searchHints),function(item,i){return _c('li',{key:`sh-${item.kinopoisk_id}-${i}`},[_c('div',{staticClass:"poster"},[_c('img',{staticClass:"lazyload",attrs:{"data-src":_vm.filmPoster(item),"src":"/static_files/no-poster.png","alt":item.title_ru}})]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(item.title_ru))]),_vm._v(" "),(item.material_data.countries.length)?_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.material_data.countries[0]))]):_vm._e(),_vm._v(" "),(item.material_data.year)?_c('span',[_vm._v(_vm._s(item.material_data.year))]):_vm._e(),_vm._v(" "),(item.material_data.genres.length && item.material_data.year)?[_vm._v("·")]:_vm._e(),_vm._v(" "),(item.type)?_c('span',[_vm._v("\n            "+_vm._s(item.type === 1
                ? 'фильм'
                : item.type === 2
                  ? 'сериал'
                  : item.type === 3
                    ? 'аниме'
                    : item.type === 4
                      ? 'аниме сериал'
                      : item.type === 5
                        ? 'tv-шоу'
                        : '')+",\n          ")]):_vm._e(),_vm._v(" "),(item.material_data.genres.length)?_c('span',[_vm._v(_vm._s(item.material_data.genres.join(', ')))]):_vm._e()],2),_vm._v(" "),_c('a',{attrs:{"href":`/film/${_vm.postUrl(item.kinopoisk_id, item.title_ru)}`}})])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }