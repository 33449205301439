<template>
  <div class="cln" :class="{ 'mobile': !isDesktop }">
    <Mobile v-if="!isDesktop" />
    <nuxt />
    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue')
  },
  computed: mapState(['error', 'isDesktop']),
}
</script>
