export default {
  methods: {
    lazyLoadImgs() {
      const list = document.querySelectorAll('img.lazy')
      const docHeight = document.documentElement.clientHeight
      const docWidth = document.documentElement.clientWidth
      if (list) {
        list.forEach(img => {
          const topPosition = docHeight - img.getBoundingClientRect().top
          const leftPosition =  img.getBoundingClientRect().left - docWidth
          if (
            +topPosition > -500 &&
            +leftPosition < 0 &&
            // +topPosition > -100 &&
            // +leftPosition < 100 &&
            (
              img.src.includes('data:image') ||
              img.src.includes('no-poster.png') ||
              img.src.includes('no-avatar.svg')
            )
          ) {
            img.src = '/'
            img.src = img.dataset.src
          }
        })
      }
    },
    postUrl(id, title) {
      if (title) {
        title = title.indexOf('(') >= 0
          ? title.slice(0, title.indexOf('(') + 1)
          : title
        const str = title
          .trim()
          .toLowerCase()
          .replace(/[^a-zа-я0-9]/g, ' ')
          .replace(/ {2,}/g, ' ')
        const c = {
          а: 'a',
          б: 'b',
          в: 'v',
          г: 'g',
          д: 'd',
          е: 'e',
          ё: 'jo',
          ж: 'zh',
          з: 'z',
          и: 'i',
          й: 'j',
          к: 'k',
          л: 'l',
          м: 'm',
          н: 'n',
          о: 'o',
          п: 'p',
          р: 'r',
          с: 's',
          т: 't',
          у: 'u',
          ф: 'f',
          х: 'h',
          ц: 'c',
          ч: 'ch',
          ш: 'sh',
          щ: 'shch',
          ъ: '',
          ы: 'y',
          ь: '',
          э: 'e',
          ю: 'ju',
          я: 'ja',
          ' ': '-',
          ';': '',
          ':': '',
          ',': '',
          '—': '-',
          '–': '-',
          '.': '',
          '«': '',
          '»': '',
          '"': '',
          "'": '',
          '@': ''
        }
        let newStr = ''
        for (let i = 0; i < str.length; i++) {
          const ch = str.charAt(i)
          newStr += ch in c ? c[ch] : ch
        }
        newStr = newStr.replace(/-$/g, '')
        return `${id}-${newStr}`
      } else {
        return `${id}`
      }
    },
    filmPoster(film) {
      let poster = '/static_files/no-poster.png'
      try {
        if (film.material_data && film.material_data.poster) {
          poster = film.material_data.poster
        } else if (film.kinopoisk_id) {
          poster = `https://st.kp.yandex.net/images/film_iphone/iphone360_${film.kinopoisk_id}.jpg`
        }
        return poster
      } catch (error) {
        console.error('[filmPoster]', error)
        return poster
      }
    },
  }
}
