<template>
  <div class="search">
    <div style="position: relative;">
      <input
        id="search"
        ref="ssearch"
        type="search"
        name="search"
        v-model="searchQuery"
        :class="{ active: searchQuery }"
        @input="trottled"
        @focus="focused = true"
        @blur="focused = false"
        autocomplete="off"
        placeholder="Начните вводить название"
      >
      <div v-if="searchQuery.length" @click="searchQuery = ''" class="clr"></div>
    </div>
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>Ничего не найдено</span>
      </div>
      <ul v-else>
        <li v-for="(item, i) in searchHints" :key="`sh-${item.kinopoisk_id}-${i}`">
          <div class="poster">
            <img
              :data-src="filmPoster(item)"
              src="/static_files/no-poster.png"
              :alt="item.title_ru"
              class="lazyload"
            >
          </div>
          <p>
            <strong>{{ item.title_ru }}</strong>
            <span v-if="item.material_data.countries.length" style="text-transform: uppercase;">{{ item.material_data.countries[0] }}</span>
            <span v-if="item.material_data.year">{{ item.material_data.year }}</span>
            <template v-if="item.material_data.genres.length && item.material_data.year">&middot;</template>
            <span v-if="item.type">
              {{
                item.type === 1
                  ? 'фильм'
                  : item.type === 2
                    ? 'сериал'
                    : item.type === 3
                      ? 'аниме'
                      : item.type === 4
                        ? 'аниме сериал'
                        : item.type === 5
                          ? 'tv-шоу'
                          : ''
              }},
            </span>
            <span v-if="item.material_data.genres.length">{{ item.material_data.genres.join(', ') }}</span>
          </p>
          <a :href="`/film/${postUrl(item.kinopoisk_id, item.title_ru)}`"></a>
          <!-- <a
            :href="`${
              item.type === 1 || item.type === 5
              ? `https://1xfilms.ru/film/${postUrl(item.kinopoisk_id, item.title_ru)}`
              : item.type === 2
                ? `https://1xanime.ru/film/${postUrl(item.kinopoisk_id, item.title_ru)}`
                : item.type === 3 || item.type === 4
                  ? `https://1xanime.ru/film/${postUrl(item.kinopoisk_id, item.title_ru)}`
                  : ''
            }`"></a> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.searchHints = data
      }
    }
  }
}
</script>

<style lang="stylus">
.search
  position: relative
  input
    position: relative
    font-weight: 400
    &::placeholder
      color: rgba(255, 255, 255, 0.35)
  .clr
    position: absolute
    top: 8px
    right: 8px
    width: 18px
    height: 18px
    background-image: url('/static_files/icons/cancel-dark.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: 6px
    background-color: #3d3d3d
    border-radius: 50%
    z-index: 1
    opacity: 0.5
    transition: opacity 0.2s ease
    cursor: pointer
    &:hover
      opacity: 1
  .datalist
    position: absolute
    padding-bottom: 10px
    width: 100%
    color #fff
    background-color #222
    border-radius: 4px
    box-shadow 0 0 10px 0px rgba(0, 0, 0, 0.2)
    z-index 4
    .no-match
      padding 20px
      a
        color #fff
    ul
      list-style none
      padding 10px 0px
      max-height 400px
      overflow auto
      -webkit-overflow-scrolling touch
      &::-webkit-scrollbar-track
        width: 14px
        background #222
      &::-webkit-scrollbar
        width: 14px
      &::-webkit-scrollbar-thumb
        background #222
      &:hover::-webkit-scrollbar-thumb
        background #3d3d3d
      li
        display grid
        grid-template-columns 50px auto
        grid-gap 0px 10px
        align-items start
        padding 7px 15px
        cursor pointer
        position: relative
        a
          position: absolute
          top: 0px
          left: 0px
          width: 100%
          height: 100%
          z-index: 1
        .poster
          position relative
          width 50px
          height 80px
          background-color: #2d2d2d
          border-radius: 4px
          overflow hidden
          img
            width 100%
            min-height 100%
            object-fit cover
        p
          width calc(100% - 65px)
          overflow hidden
          strong
            display block
            width 100%
            font-size 1.4rem
            border none
          span
            font-size 1.2rem
            text-transform lowercase
            opacity 0.75
      li:hover
        background: rgba(0, 0, 0, 0.15)
        strong
          color: rgba(207, 161, 0, 1)
</style>
